export const STRICTLY_US_STATES = {
  DE: 'Delaware',
  CA: 'California',
  TX: 'Texas',
  NY: 'New York',
  FL: 'Florida',
  WA: 'Washington',
  IL: 'Illinois',
  PA: 'Pennsylvania',
  OH: 'Ohio',
  GA: 'Georgia',
  NC: 'North Carolina',
  MI: 'Michigan',
  NJ: 'New Jersey',
  VA: 'Virginia',
  AZ: 'Arizona',
  MA: 'Massachusetts',
  TN: 'Tennessee',
  IN: 'Indiana',
  MO: 'Missouri',
  MD: 'Maryland',
  WI: 'Wisconsin',
  CO: 'Colorado',
  MN: 'Minnesota',
  SC: 'South Carolina',
  AL: 'Alabama',
  LA: 'Louisiana',
  KY: 'Kentucky',
  OR: 'Oregon',
  OK: 'Oklahoma',
  CT: 'Connecticut',
  UT: 'Utah',
  IA: 'Iowa',
  NV: 'Nevada',
  AR: 'Arkansas',
  MS: 'Mississippi',
  KS: 'Kansas',
  NM: 'New Mexico',
  NE: 'Nebraska',
  WV: 'West Virginia',
  ID: 'Idaho',
  HI: 'Hawaii',
  NH: 'New Hampshire',
  ME: 'Maine',
  MT: 'Montana',
  RI: 'Rhode Island',
  SD: 'South Dakota',
  ND: 'North Dakota',
  AK: 'Alaska',
  DC: 'District of Columbia',
  VT: 'Vermont',
  WY: 'Wyoming',
  PR: 'Puerto Rico',
};

export const US_STATES = {
  DE: 'Delaware',
  CA: 'California',
  TX: 'Texas',
  NY: 'New York',
  FL: 'Florida',
  WA: 'Washington',
  IL: 'Illinois',
  PA: 'Pennsylvania',
  OH: 'Ohio',
  GA: 'Georgia',
  NC: 'North Carolina',
  MI: 'Michigan',
  NJ: 'New Jersey',
  VA: 'Virginia',
  AZ: 'Arizona',
  MA: 'Massachusetts',
  TN: 'Tennessee',
  IN: 'Indiana',
  MO: 'Missouri',
  MD: 'Maryland',
  WI: 'Wisconsin',
  CO: 'Colorado',
  MN: 'Minnesota',
  SC: 'South Carolina',
  AL: 'Alabama',
  LA: 'Louisiana',
  KY: 'Kentucky',
  OR: 'Oregon',
  OK: 'Oklahoma',
  CT: 'Connecticut',
  UT: 'Utah',
  IA: 'Iowa',
  NV: 'Nevada',
  AR: 'Arkansas',
  MS: 'Mississippi',
  KS: 'Kansas',
  NM: 'New Mexico',
  NE: 'Nebraska',
  WV: 'West Virginia',
  ID: 'Idaho',
  HI: 'Hawaii',
  NH: 'New Hampshire',
  ME: 'Maine',
  MT: 'Montana',
  RI: 'Rhode Island',
  SD: 'South Dakota',
  ND: 'North Dakota',
  AK: 'Alaska',
  DC: 'District of Columbia',
  VT: 'Vermont',
  WY: 'Wyoming',
  PR: 'Puerto Rico',
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NT: 'Northwest Territories',
  NS: 'Nova Scotia',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon Territory',
  OTHER: 'Other / International',
};

export const CA_STATES = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NT: 'Northwest Territories',
  NS: 'Nova Scotia',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon Territory',
};

export const US_AND_INTL = {
  AK: 'Alaska (AK)',
  AL: 'Alabama (AL)',
  AR: 'Arkansas (AR)',
  AZ: 'Arizona (AZ)',
  CA: 'California (CA)',
  CO: 'Colorado (CO)',
  CT: 'Connecticut (CT)',
  DC: 'District of Columbia (DC)',
  DE: 'Delaware (DE)',
  FL: 'Florida (FL)',
  GA: 'Georgia (GA)',
  HI: 'Hawaii (HI)',
  IA: 'Iowa (IA)',
  ID: 'Idaho (ID)',
  IL: 'Illinois (IL)',
  IN: 'Indiana (IN)',
  KS: 'Kansas (KS)',
  KY: 'Kentucky (KY)',
  LA: 'Louisiana (LA)',
  MA: 'Massachusetts (MA)',
  MD: 'Maryland (MD)',
  ME: 'Maine (ME)',
  MI: 'Michigan (MI)',
  MN: 'Minnesota (MN)',
  MO: 'Missouri (MO)',
  MS: 'Mississippi (MS)',
  MT: 'Montana (MT)',
  NC: 'North Carolina (NC)',
  ND: 'North Dakota (ND)',
  NE: 'Nebraska (NE)',
  NH: 'New Hampshire (NH)',
  NJ: 'New Jersey (NJ)',
  NM: 'New Mexico (NM)',
  NV: 'Nevada (NV)',
  NY: 'New York (NY)',
  OH: 'Ohio (OH)',
  OK: 'Oklahoma (OK)',
  OR: 'Oregon (OR)',
  PA: 'Pennsylvania (PA)',
  PR: 'Puerto Rico (PR)',
  RI: 'Rhode Island (RI)',
  SC: 'South Carolina (SC)',
  SD: 'South Dakota (SD)',
  TN: 'Tennessee (TN)',
  TX: 'Texas (TX)',
  UT: 'Utah (UT)',
  VA: 'Virginia (VA)',
  VT: 'Vermont (VT)',
  WA: 'Washington (WA)',
  WI: 'Wisconsin (WI)',
  WV: 'West Virginia (WV)',
  WY: 'Wyoming (WY)',
  AFG: 'Afghanistan (AFG)',
  ALA: 'Åland Islands (ALA)',
  ALB: 'Albania (ALB)',
  DZA: 'Algeria (DZA)',
  ASM: 'American Samoa (ASM)',
  AND: 'Andorra (AND)',
  AGO: 'Angola (AGO)',
  AIA: 'Anguilla (AIA)',
  ATA: 'Antarctica (ATA)',
  ATG: 'Antigua and Barbuda (ATG)',
  ARG: 'Argentina (ARG)',
  ARM: 'Armenia (ARM)',
  ABW: 'Aruba (ABW)',
  AUS: 'Australia (AUS)',
  AUT: 'Austria (AUT)',
  AZE: 'Azerbaijan (AZE)',
  BHS: 'Bahamas (BHS)',
  BHR: 'Bahrain (BHR)',
  BGD: 'Bangladesh (BGD)',
  BRB: 'Barbados (BRB)',
  BLR: 'Belarus (BLR)',
  BEL: 'Belgium (BEL)',
  BLZ: 'Belize (BLZ)',
  BEN: 'Benin (BEN)',
  BMU: 'Bermuda (BMU)',
  BTN: 'Bhutan (BTN)',
  BOL: 'Bolivia (BOL)',
  BES: 'Bonaire, Sint Eustatius and Saba (BES)',
  BIH: 'Bosnia and Herzegovina (BIH)',
  BWA: 'Botswana (BWA)',
  BVT: 'Bouvet Island (BVT)',
  BRA: 'Brazil (BRA)',
  IOT: 'British Indian Ocean Territory (IOT)',
  BRN: 'Brunei Darussalam (BRN)',
  BGR: 'Bulgaria (BGR)',
  BFA: 'Burkina Faso (BFA)',
  BDI: 'Burundi (BDI)',
  CPV: 'Cabo Verde (CPV)',
  KHM: 'Cambodia (KHM)',
  CMR: 'Cameroon (CMR)',
  CAN: 'Canada (CAN)',
  CYM: 'Cayman Islands (CYM)',
  CAF: 'Central African Republic (CAF)',
  TCD: 'Chad (TCD)',
  CHL: 'Chile (CHL)',
  CHN: 'China (CHN)',
  CXR: 'Christmas Island (CXR)',
  CCK: 'Cocos (Keeling) Islands (CCK)',
  COL: 'Colombia (COL)',
  COM: 'Comoros (COM)',
  COG: 'Congo (COG)',
  COD: 'Congo, Democratic Republic of the (COD)',
  COK: 'Cook Islands (COK)',
  CRI: 'Costa Rica (CRI)',
  CIV: "Côte d'Ivoire (CIV)",
  HRV: 'Croatia (HRV)',
  CUB: 'Cuba (CUB)',
  CUW: 'Curaçao (CUW)',
  CYP: 'Cyprus (CYP)',
  CZE: 'Czechia (CZE)',
  DNK: 'Denmark (DNK)',
  DJI: 'Djibouti (DJI)',
  DMA: 'Dominica (DMA)',
  DOM: 'Dominican Republic (DOM)',
  ECU: 'Ecuador (ECU)',
  EGY: 'Egypt (EGY)',
  SLV: 'El Salvador (SLV)',
  GNQ: 'Equatorial Guinea (GNQ)',
  ERI: 'Eritrea (ERI)',
  EST: 'Estonia (EST)',
  SWZ: 'Eswatini (SWZ)',
  ETH: 'Ethiopia (ETH)',
  FLK: 'Falkland Islands (Malvinas) (FLK)',
  FRO: 'Faroe Islands (FRO)',
  FJI: 'Fiji (FJI)',
  FIN: 'Finland (FIN)',
  FRA: 'France (FRA)',
  GUF: 'French Guiana (GUF)',
  PYF: 'French Polynesia (PYF)',
  ATF: 'French Southern Territories (ATF)',
  GAB: 'Gabon (GAB)',
  GMB: 'Gambia (GMB)',
  GEO: 'Georgia (GEO)',
  DEU: 'Germany (DEU)',
  GHA: 'Ghana (GHA)',
  GIB: 'Gibraltar (GIB)',
  GRC: 'Greece (GRC)',
  GRL: 'Greenland (GRL)',
  GRD: 'Grenada (GRD)',
  GLP: 'Guadeloupe (GLP)',
  GUM: 'Guam (GUM)',
  GTM: 'Guatemala (GTM)',
  GGY: 'Guernsey (GGY)',
  GIN: 'Guinea (GIN)',
  GNB: 'Guinea-Bissau (GNB)',
  GUY: 'Guyana (GUY)',
  HTI: 'Haiti (HTI)',
  HMD: 'Heard Island and McDonald Islands (HMD)',
  VAT: 'Holy See (VAT)',
  HND: 'Honduras (HND)',
  HKG: 'Hong Kong (HKG)',
  HUN: 'Hungary (HUN)',
  ISL: 'Iceland (ISL)',
  IND: 'India (IND)',
  IDN: 'Indonesia (IDN)',
  IRN: 'Iran (Islamic Republic of) (IRN)',
  IRQ: 'Iraq (IRQ)',
  IRL: 'Ireland (IRL)',
  IMN: 'Isle of Man (IMN)',
  ISR: 'Israel (ISR)',
  ITA: 'Italy (ITA)',
  JAM: 'Jamaica (JAM)',
  JPN: 'Japan (JPN)',
  JEY: 'Jersey (JEY)',
  JOR: 'Jordan (JOR)',
  KAZ: 'Kazakhstan (KAZ)',
  KEN: 'Kenya (KEN)',
  KIR: 'Kiribati (KIR)',
  PRK: "Korea (Democratic People's Republic of) (PRK)",
  KOR: 'Korea, Republic of (KOR)',
  KWT: 'Kuwait (KWT)',
  KGZ: 'Kyrgyzstan (KGZ)',
  LAO: "Lao People's Democratic Republic (LAO)",
  LVA: 'Latvia (LVA)',
  LBN: 'Lebanon (LBN)',
  LSO: 'Lesotho (LSO)',
  LBR: 'Liberia (LBR)',
  LBY: 'Libya (LBY)',
  LIE: 'Liechtenstein (LIE)',
  LTU: 'Lithuania (LTU)',
  LUX: 'Luxembourg (LUX)',
  MAC: 'Macao (MAC)',
  MDG: 'Madagascar (MDG)',
  MWI: 'Malawi (MWI)',
  MYS: 'Malaysia (MYS)',
  MDV: 'Maldives (MDV)',
  MLI: 'Mali (MLI)',
  MLT: 'Malta (MLT)',
  MHL: 'Marshall Islands (MHL)',
  MTQ: 'Martinique (MTQ)',
  MRT: 'Mauritania (MRT)',
  MUS: 'Mauritius (MUS)',
  MYT: 'Mayotte (MYT)',
  MEX: 'Mexico (MEX)',
  FSM: 'Micronesia (Federated States of) (FSM)',
  MDA: 'Moldova, Republic of (MDA)',
  MCO: 'Monaco (MCO)',
  MNG: 'Mongolia (MNG)',
  MNE: 'Montenegro (MNE)',
  MSR: 'Montserrat (MSR)',
  MAR: 'Morocco (MAR)',
  MOZ: 'Mozambique (MOZ)',
  MMR: 'Myanmar (MMR)',
  NAM: 'Namibia (NAM)',
  NRU: 'Nauru (NRU)',
  NPL: 'Nepal (NPL)',
  NLD: 'Netherlands (NLD)',
  NCL: 'New Caledonia (NCL)',
  NZL: 'New Zealand (NZL)',
  NIC: 'Nicaragua (NIC)',
  NER: 'Niger (NER)',
  NGA: 'Nigeria (NGA)',
  NIU: 'Niue (NIU)',
  NFK: 'Norfolk Island (NFK)',
  MKD: 'North Macedonia (MKD)',
  MNP: 'Northern Mariana Islands (MNP)',
  NOR: 'Norway (NOR)',
  OMN: 'Oman (OMN)',
  PAK: 'Pakistan (PAK)',
  PLW: 'Palau (PLW)',
  PSE: 'Palestine, State of (PSE)',
  PAN: 'Panama (PAN)',
  PNG: 'Papua New Guinea (PNG)',
  PRY: 'Paraguay (PRY)',
  PER: 'Peru (PER)',
  PHL: 'Philippines (PHL)',
  PCN: 'Pitcairn (PCN)',
  POL: 'Poland (POL)',
  PRT: 'Portugal (PRT)',
  PRI: 'Puerto Rico (PRI)',
  QAT: 'Qatar (QAT)',
  REU: 'Réunion (REU)',
  ROU: 'Romania (ROU)',
  RUS: 'Russian Federation (RUS)',
  RWA: 'Rwanda (RWA)',
  BLM: 'Saint Barthélemy (BLM)',
  SHN: 'Saint Helena, Ascension and Tristan da Cunha (SHN)',
  KNA: 'Saint Kitts and Nevis (KNA)',
  LCA: 'Saint Lucia (LCA)',
  MAF: 'Saint Martin (French part) (MAF)',
  SPM: 'Saint Pierre and Miquelon (SPM)',
  VCT: 'Saint Vincent and the Grenadines (VCT)',
  WSM: 'Samoa (WSM)',
  SMR: 'San Marino (SMR)',
  STP: 'Sao Tome and Principe (STP)',
  SAU: 'Saudi Arabia (SAU)',
  SEN: 'Senegal (SEN)',
  SRB: 'Serbia (SRB)',
  SYC: 'Seychelles (SYC)',
  SLE: 'Sierra Leone (SLE)',
  SGP: 'Singapore (SGP)',
  SXM: 'Sint Maarten (Dutch part) (SXM)',
  SVK: 'Slovakia (SVK)',
  SVN: 'Slovenia (SVN)',
  SLB: 'Solomon Islands (SLB)',
  SOM: 'Somalia (SOM)',
  ZAF: 'South Africa (ZAF)',
  SGS: 'South Georgia and the South Sandwich Islands (SGS)',
  SSD: 'South Sudan (SSD)',
  ESP: 'Spain (ESP)',
  LKA: 'Sri Lanka (LKA)',
  SDN: 'Sudan (SDN)',
  SUR: 'Suriname (SUR)',
  SJM: 'Svalbard and Jan Mayen (SJM)',
  SWE: 'Sweden (SWE)',
  CHE: 'Switzerland (CHE)',
  SYR: 'Syrian Arab Republic (SYR)',
  TWN: 'Taiwan, Province of China (TWN)',
  TJK: 'Tajikistan (TJK)',
  TZA: 'Tanzania, United Republic of (TZA)',
  THA: 'Thailand (THA)',
  TLS: 'Timor-Leste (TLS)',
  TGO: 'Togo (TGO)',
  TKL: 'Tokelau (TKL)',
  TON: 'Tonga (TON)',
  TTO: 'Trinidad and Tobago (TTO)',
  TUN: 'Tunisia (TUN)',
  TUR: 'Turkey (TUR)',
  TKM: 'Turkmenistan (TKM)',
  TCA: 'Turks and Caicos Islands (TCA)',
  TUV: 'Tuvalu (TUV)',
  UGA: 'Uganda (UGA)',
  UKR: 'Ukraine (UKR)',
  ARE: 'United Arab Emirates (ARE)',
  GBR: 'United Kingdom of Great Britain and Northern Ireland (GBR)',
  UMI: 'United States Minor Outlying Islands (UMI)',
  URY: 'Uruguay (URY)',
  UZB: 'Uzbekistan (UZB)',
  VUT: 'Vanuatu (VUT)',
  VEN: 'Venezuela (Bolivarian Republic of) (VEN)',
  VNM: 'Viet Nam (VNM)',
  VGB: 'Virgin Islands (British) (VGB)',
  VIR: 'Virgin Islands (U.S.) (VIR)',
  WLF: 'Wallis and Futuna (WLF)',
  ESH: 'Western Sahara (ESH)',
  YEM: 'Yemen (YEM)',
  ZMB: 'Zambia (ZMB)',
  ZWE: 'Zimbabwe (ZWE)',
};

export const STATES = {
  US: US_STATES,
  CA: CA_STATES,
};

export const getUSStateOptions = (stateNameAsValue = false) => {
  const countryStates = STRICTLY_US_STATES;
  if (countryStates) {
    return Object.keys(countryStates).map((stateId) => ({
      value: stateNameAsValue ? countryStates[stateId] : stateId,
      label: countryStates[stateId],
    }));
  }
  return [];
};

// this will return [{ value: 'IL', label: 'Illinois' }]
export const getStateOptions = (country) => {
  const countryStates = STATES[country];
  if (countryStates) {
    return Object.keys(countryStates).map((stateId) => ({
      value: stateId,
      label: countryStates[stateId],
    }));
  }
  return [];
};

export const getStateAndIntlOptions = () => {
  const countryStates = US_AND_INTL;
  if (countryStates) {
    return Object.keys(countryStates).map((stateId) => ({
      value: stateId,
      label: countryStates[stateId],
    }));
  }
  return [];
};

// this will return [{ value: 'Illinois', label: 'Illinois' }]
export const getStateNameOptions = (country) => {
  const countryStates = STATES[country];
  if (countryStates) {
    return Object.values(countryStates).map((stateName) => ({
      value: stateName,
      label: stateName,
    }));
  }
  return [];
};

export const getStateNameByCode = (country, stateCode) => {
  const countryStates = STATES[country];
  if (countryStates) {
    return countryStates[stateCode];
  }
  return stateCode;
};

export const getCodeByStateName = (country, stateName) => {
  const countryStates = STATES[country];
  if (countryStates && stateName) {
    return Object.keys(countryStates).find(
      (stateCode) =>
        countryStates[stateCode].toUpperCase() === stateName.toUpperCase() ||
        stateName.toUpperCase() === stateCode.toUpperCase(),
    );
  }
  return stateName;
};
